
const modals = (state = { show: false, content: {} }, action) => {
    switch(action.type){
        case 'SHOW_MODAL':
            return new Object({
                show: true,
                content: action.content
            });
        case 'HIDE_MODAL':
            return new Object({
                show: false,
                content: action.content
            });
    }

    return state;
};

export default modals;