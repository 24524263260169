import { combineReducers } from 'redux';

import user from './user';
import screen from './screenReducer';
import modals from './modals';
import filtersReducer from './filtersRegucer';

export default combineReducers({
    user,
    modals,
    filtersReducer,
    screen,
});
