import React, { Component } from 'react';
import Modals from '../etc/Modals';

import Header from './Header';

class Layout extends Component {

    render(){
        return (
            <div>

                <Header />

                {this.props.children}

                <footer className="footer section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                            </div>
                        </div>
                        <div className="row df fw-wrap">
                            <div className="col-lg-7 col-md-7 col-sm-9 col-xs-12 df ai-center fw-wrap row-footer">

                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-3 col-xs-12">

                            </div>
                        </div>
                    </div>

                </footer>
                <Modals />
            </div>
        )
    }
}

export default Layout;
