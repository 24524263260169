import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import api from '../api/index';
import { initData, login } from '../actions';
import {toPascalCase} from "../services/helpers";

import { myMSALObj, signIn } from '../helpers/msalAuth2'

async function loadConfig(props) {
    const accessToken = await signIn();
    if (!accessToken) return

    props.dispatchLogin({
        userCredentials: {
            token: accessToken, authContext: myMSALObj
        }
    });

    let departmentCompanyList = await api.apiDepartmentGetCompanyList();
    departmentCompanyList.data.value = toPascalCase(departmentCompanyList.data.value)

    let departmentCompanyListArray = departmentCompanyList.data.value.map((item, i) => {
        return { value: item.ID, label: item.Name };
    });

    let typeTermList = await api.apiGetTypeTermList();
    typeTermList.data.value = toPascalCase(typeTermList.data.value)

    let typeTermListArray = typeTermList.data.value.map((item, i) => {
        return { value: item.ID, label: item.TermName };
    });
    props.dispatchGetInitData({
        typeTermList: typeTermListArray,
        departmentCompanyList: departmentCompanyListArray
    });
}

const Auth = (props) => {
    useEffect(() => {
        loadConfig(props);
    }, []);
    return props.auth === null ? <div/> : props.children
}

const mapStateToProps = (state) => {
    return {
        auth: state.user.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetInitData: (data) => dispatch(initData(data)),
        dispatchLogin: (data) => dispatch(login(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
