const INITIAL_STATE = {};

const filtersReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'SET_FILTER_DATA':
            return new Object({
                ...state,
                [action.filterType]: action.data
            });
        
        case 'CLEAR_FILTERS':
            return {
                ...INITIAL_STATE
            };
    }

    return state;
};

export default filtersReducer;