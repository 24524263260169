import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import DashboardRoute from './dashboard/DashboardRoute'
import Card from './dashboard/cod/items/card/index';
import Company from './dashboard/cod/items/company/index';
import Employee from './dashboard/cod/items/employee/index';
import Register from './dashboard/cod/items/register/index';
import CompanyMovement from './dashboard/cod/items/companyMovement/index';
import RegisterAudit from './dashboard/cod/items/registerAudit/index';
import CodPrice from './dashboard/cod/items/price/index';
import Reports from './dashboard/reports/index';
import Layout from './general/Layout';

const App = () => (
  <Layout>
    <Switch>
      <DashboardRoute exact path="/" component={Card} />
      <DashboardRoute exact path="/cod/company" component={Company} />
      <DashboardRoute exact path="/cod/employee" component={Employee} />
      <DashboardRoute exact path="/cod/register" component={Register} />
      <DashboardRoute exact path="/cod/price" component={CodPrice} />
      <DashboardRoute exact path="/cod/company-movement" component={CompanyMovement} />
      <DashboardRoute exact path="/cod/register-audit" component={RegisterAudit} />
      <DashboardRoute exact path="/reports" component={Reports} />
      <Redirect to='/audit'/>
    </Switch>
  </Layout>

);

export default App;
