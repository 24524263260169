import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce'

const NumberInput = ({ filter: { value: { min = '', max = '' } = {}} = {}, onChange }) => {
    const [v, setV] = useState({ min, max });

    useEffect(() => {
        setV({ min, max })
    }, [min, max])
    const debouncedChangeHandler = useCallback(
        debounce(onChange, 400)
    , []);
    return (
        <>
            <input 
                placeholder="Від"
                type="number" 
                value={v.min} 
                onChange={(e) => {
                    setV({ min: e.target.value, max });
                    debouncedChangeHandler({ min: e.target.value, max })
                }}
                style={{ width: 'calc(50% - 7px)' }} 
            />
            {' - '}
            <input 
                placeholder="До"
                type="number" 
                value={v.max} 
                onChange={(e) => {
                    setV({ min, max: e.target.value });
                    debouncedChangeHandler({ min, max: e.target.value })
                }} 
                style={{ width: 'calc(50% - 7px)' }} 
            />
        </>
    )
};

export default NumberInput;