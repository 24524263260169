export const setFilterData = (data, filterType) => ({
    type: 'SET_FILTER_DATA',
    filterType,
    data, 
});

export function clearFilters() {
    return {
        type: 'CLEAR_FILTERS'
    }
}