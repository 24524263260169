import React, { Component } from 'react';
import ReactTable from "react-table";
import { selectDefTableSize } from '../../../../../../selectors/screenSelectors';
import { connect } from 'react-redux';
import CheckboxFilter from "../../../../../common/table/Checkbox";
import StringInput from '../../../../../common/table/StringInput';

class Table extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    render(){
        const { defaultPageSize } = this.props;
        return (
            <div className={`table-main-wrap mt-50 ${!this.props.propsArray.length && this.props.propsGetDataStatus == 'pending' ? 'table-main-wrap_loading' : '' }`}>
                <div className="table-header">Інформація про картки</div>
                <ReactTable
                    getTheadFilterThProps={(rowInfo) => {
                        return {
                            style: {
                                overflow: 'visible',
                                textAlign: 'left'
                            }
                        }
                    }}
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: () => {
                                    this.props.propsRowClick({
                                        id: rowInfo.row._original.ID,
                                        index: rowInfo.index
                                    });
                                },
                                style: {
                                    backgroundColor: rowInfo.index === this.props.index ? '#B8B8B8' : 'white',
                                    color: rowInfo.index === this.props.index ? 'white' : 'black',
                                    cursor: 'pointer',
                                }
                            }
                        }else{
                            return {}
                        }
                    }}
                    data={this.props.propsArray}
                    columns={[
                        {
                            Filter: StringInput,
                            Header: "Назва картки",
                            id: 'Name',
                            accessor: "Name",
                            Cell: (row) => {
                                if (!row.original) return (
                                    <div><i className="circle" /></div>
                                );
                                const { original: { Employees } } = row;
                                return (
                                    <div>
                                        {
                                            Employees.length
                                                ? <i className="circle" />
                                                : (
                                                    <i className="green circle" />
                                                )
                                        }
                                        {' '}
                                        {row.original.Name}
                                    </div>
                                )
                            },
                        },
                        {
                            Filter: CheckboxFilter,
                            Header: "Заблоковано",
                            id: "Closed",
                            accessor: item => {
                                return <input type="checkbox" checked={item.Closed ? true : false} readOnly/>
                            }
                        }
                    ]}
                    defaultPageSize={defaultPageSize}
                    className=" -highlight"
                    pageSize={this.props.pageSize}
                    page={this.props.page}
                    onPageSizeChange={this.props.handleChangePageSize}
                    onPageChange={this.props.handleChangePage}
                    manual={true}
                    filterable
                    sortable
                    multiSort
                    onFilteredChange={this.props.handleOnFilterChange}
                    onSortedChange={this.props.handleOnSortChange}
                    filtered={this.props.filtered}
                    sorted={this.props.sorted}
                    pages={Math.ceil(this.props.count / this.props.pageSize)}
                    NoDataComponent={() => null}
                    previousText="Назад"
                    nextText="Вперед"
                    rowsText="рядків"
                    pageText="Сторінка"
                    ofText="з"
                />

                <div className="loading"></div>
            </div>
        )

    }
}

const mapStateToProps = state => ({
    defaultPageSize: selectDefTableSize(state),
});

export default connect(mapStateToProps)(Table);
