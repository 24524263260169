import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import {normalizeNumberMaxDecimals, validateText, renderError, toPascalCase} from "../../../../../../services/helpers";
import validator from "../../../../../../services/DataValidator";
import api from "../../../../../../api";
import { hideModal, showModal } from "../../../../../../actions/modals";
import Spiner from "../../../../../common/Spiner";

class EmployeeEditModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: {
                companyList: [],
                departmentList: [],
                cardList: []
            },

            ID: '',
            company: '',
            department: '',
            surname: '',
            name: '',
            patronymic: '',
            phoneNumber: '',
            card: '',
            password: '',
            limit: '',
            typeTerm: null,
            closed: false,

            callback: () => {},

            validation: []
        };
    }

    async componentDidMount(){
        this.setState({
            data: {
                companyList: [{
                    value: this.props.propsData.Department.Company.ID, label: this.props.propsData.Department.Company.Name
                }],
                departmentList: [{
                    value: this.props.propsData.Department.ID, label: this.props.propsData.Department.Name
                }],
                cardList: [{
                    value: this.props.propsData.Card.ID, label: this.props.propsData.Card.Name
                }]
            },

            ID: this.props.propsData.ID,

            department: {
                value: this.props.propsData.Department.ID, label: this.props.propsData.Department.Name
            },
            surname: this.props.propsData.Surname,
            patronymic: this.props.propsData.Patronymic,
            phoneNumber: this.props.propsData.PhoneNumber,
            card: {
                value: this.props.propsData.Card.ID, label: this.props.propsData.Card.Name
            },
            closed: this.props.propsData.Closed,
            name: this.props.propsData.Name,
            limit: this.props.propsData.Limit === null ? '' : this.props.propsData.Limit,
            company: {
                value: this.props.propsData.Department.Company.ID, label: this.props.propsData.Department.Company.Name
            },
            typeTerm: this.props.propsData.TypeTermID !== null ? {
                value: this.props.propsData.TypeTerm.ID, label: this.props.propsData.TypeTerm.TermName
            } : null,
            callback: this.props.propsData.callback

        });
        let [
            companyList,
            departmentList,
            cardList,
        ]= await Promise.all([
            api.apiGetCompanyData(),
            api.apiGetDepartmentData({
                ID: this.props.propsData.Department.Company.ID,
            }),
            api.apiGetCardData(),
        ]);
        companyList.data.value = toPascalCase(companyList.data.value);
        let companyListArray = companyList.data.value.map((item, i) => {
            return { value: item.ID, label: item.Name };
        });
        departmentList.data.value = toPascalCase(departmentList.data.value)

        let departmentListArray = departmentList.data.value.map((item, i) => {
            return { value: item.ID, label: item.Name };
        });
        cardList.data.value = toPascalCase(cardList.data.value);

        let cardListArray = cardList.data.value.map((item, i) => {
            return { value: item.ID, label: item.Name };
        });

        this.setState({
            data: {
                companyList: companyListArray,
                departmentList: departmentListArray,
                cardList: cardListArray
            },
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(event.target.dataset.type == 'number'){
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        if(event.target.dataset.type == 'text'){
            value = validateText(value);
        }

        this.setState({
            [name]: value
        });
    };

    handleCompany = async (selectedOption) => {
        let departmentList = await api.apiGetDepartmentData({ ID: selectedOption.value });
        departmentList.data.value = toPascalCase(departmentList.data.value)

        let departmentListArray = departmentList.data.value.map((item, i) => {
            return { value: item.ID, label: item.Name };
        });

        const data = this.state.data
        data.departmentList = departmentListArray
        const { company, department } = this.state;
        this.setState({
            company: selectedOption,
            department: Number(company.value) !== Number(selectedOption.value) ? null : department,
            data
        });
    };

    handleTypeTerm = (selectedOption) => {
        this.setState({
            typeTerm: selectedOption
        });
    };

    handleSave = () => {

        let validationResult = validator.arrayValidation([
            { id: 'company', name: 'Компанія', type: 'EMPTY', value: this.state.company },
            { id: 'department', name: 'Відділ', type: 'EMPTY', value: this.state.department },
            { id: 'surname', name: 'Прізвище', type: 'EMPTY', value: this.state.surname },
            { id: 'name', name: 'Ім`я', type: 'EMPTY', value: this.state.name },
            { id: 'patronymic', name: 'По-батькові', type: 'EMPTY', value: this.state.patronymic },
            { id: 'phoneNumber', name: 'Номер телефону', type: 'EMPTY', value: this.state.phoneNumber },
            { id: 'card', name: 'Картка', type: 'EMPTY', value: this.state.card },
            { id: 'password', name: 'Пароль', type: 'EMPTY', value: this.state.password || this.props.propsData.Password },
        ]);

        if(validationResult.state == 'success'){
            this.setState({ loading: true });
            let dataToSend = {
                ID: +this.state.ID,
                CardID: +this.state.card.value,
                Closed: this.state.closed,
                DepartmentID: +this.state.department.value,
                Name: this.state.name,
                Surname: this.state.surname,
                Patronymic: this.state.patronymic,
                Password: this.state.password || this.props.propsData.Password,
                PhoneNumber: this.state.phoneNumber,
                Limit: !(+this.state.limit) ? null : +this.state.limit,
                TypeTermID: this.state.typeTerm == null ? null : +this.state.typeTerm.value
            };

            api.apiEditEmployee(dataToSend).then(res => {
                this.setState({ loading: false });
                if(res.status == 204){
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагувати працівника', description: 'Працівник був успішно відредагований', type: true });
                    this.state.callback();
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагувати працівника', description: 'Працівник не був відредагований. Зверніться до адміністратора', type: false });
                }
            }).catch(error => {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагувати працівника', description: error.response.data.message, type: false });
            });
        }

        this.setState({
            validation: validationResult.errorArr
        });
    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Редагувати працівника</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Компанія</h5>
                                <div>
                                    <Select
                                        isDisabled
                                        placeholder="Виберіть компанію"
                                        value={this.state.company}
                                        options={this.state.data.companyList}
                                    />
                                    <div className="form-group-error">
                                        { renderError('company', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Відділ</h5>
                                <div>
                                    <Select
                                        isDisabled
                                        placeholder="Виберіть відділ"
                                        value={this.state.department}
                                        options={this.state.data.departmentList}
                                    />
                                    <div className="form-group-error">
                                        { renderError('department', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Прізвище</h5>
                                <div className="form-group">
                                    <input
                                        disabled
                                        placeholder="Введіть прізвище"
                                        type="text"
                                        className="form-control"
                                        name="surname"
                                        value={this.state.surname}
                                    />
                                    <div className="form-group-error">
                                        { renderError('surname', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Ім'я</h5>
                                <div className="form-group">
                                    <input
                                        disabled
                                        placeholder="Введіть ім'я"
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={this.state.name}
                                    />
                                    <div className="form-group-error">
                                        { renderError('name', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>По-батькові</h5>
                                <div className="form-group">
                                    <input
                                        disabled
                                        placeholder="Введіть по-батькові"
                                        type="text"
                                        className="form-control"
                                        name="patronymic"
                                        value={this.state.patronymic}
                                    />
                                    <div className="form-group-error">
                                        { renderError('patronymic', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Номер телефона</h5>
                                <div className="form-group">
                                    <PhoneInput
                                        disabled
                                        placeholder="Введіть номер телефона"
                                        value={ this.state.phoneNumber }
                                    />
                                    <div className="form-group-error">
                                        { renderError('phoneNumber', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Картка</h5>
                                <div>
                                    <Select
                                        isDisabled
                                        placeholder="Виберіть картку"
                                        value={this.state.card}
                                        options={this.state.data.cardList}
                                    />
                                    <div className="form-group-error">
                                        { renderError('card', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Пароль</h5>
                                <div className="form-group">
                                    <input
                                        disabled
                                        placeholder="Введіть пароль"
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        value={this.state.password}
                                    />
                                    <div className="form-group-error">
                                        { renderError('password', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Кількість ліміту</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть кількість ліміту"
                                        type="text"
                                        className="form-control"
                                        name="limit"
                                        data-type="number"

                                        value={this.state.limit}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('limit', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Термін ліміту</h5>
                                <div>
                                    <Select
                                        placeholder="Виберіть термін ліміту"
                                        value={this.state.typeTerm}
                                        onChange={this.handleTypeTerm}
                                        options={this.props.typeTerm}
                                        isClearable
                                    />
                                    <div className="form-group-error">
                                        {renderError('typeTerm', this.state.validation)}
                                    </div>
                                </div>
                            </div>
                        </div><div className="col-lg-12">
                        <div className="modal__row">
                            <h5>Заблоковано</h5>
                            <label>
                                <input
                                    type="checkbox"
                                    name="closed"

                                    value={this.state.closed}
                                    onChange={this.handleInputChange}
                                    checked={this.state.closed}
                                />
                                <div className="form-group-error">
                                    {renderError('closed', this.state.validation)}
                                </div>
                            </label>
                        </div>
                    </div>

                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Зберегти</span>
                                        : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        typeTerm: typeof state.user.data.typeTermList != "undefined" ? state.user.data.typeTermList: []
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeEditModal);
