import React, { useState } from 'react';
import DateModal from './DateModal';
import { format } from 'date-fns'

const DatePicker = ({ filter: { value: { from, to } = {} } = {}, onChange }) => {
    const [show, setShow] = useState(false);
    const hideModal = (e) => {
        if (e && e.stopPropagation) e.stopPropagation();
        setShow(false);
    }
    const showModal = () => setShow(true);
    return (
        <div onClick={showModal} style={{
                textAlign: 'center',
                height: '100%',
                cursor: 'pointer',
                border: '1px solid rgba(0,0,0,0.1)',
                background: '#fff',
                paddingLeft: '7px',
                paddingRight: '7px',
                fontSize: 'inherit',
                borderRadius: '3px',
                fontWeight: 'normal',
                outline: 'none',
            }}
        >
            <span>Від: {!from ? '--.--.---- --:--' : format(from, 'd.M.yyyy HH:mm')}</span>
            <br />
            <span>До : {!to ? '--.--.---- --:--' : format(to, 'd.M.yyyy HH:mm')}</span>
            <DateModal from={from} to={to} show={show} hideModal={hideModal} applyDates={(value) => onChange(value)} />
        </div>
    );
}

export default DatePicker;