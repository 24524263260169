import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

const StringInput = ({ onChange, filter: { value = '' } = {}}) => {
    const [v, setV] = useState(value);

    useEffect(() => {
        console.log(value)
        setV(value)
    }, [value])
    console.log(v)
    const debouncedChangeHandler = useCallback(
        debounce(onChange, 400)
    , []);
    return (
        <input 
            style={{ width: '100%'}}
            value={v} 
            onChange={(e) => {
                setV(e.target.value);
                debouncedChangeHandler(e.target.value)
            }}
        />
    );
}

export default StringInput;