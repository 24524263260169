import React, { Component } from 'react';
import ReactTable from "react-table";
import { selectDefTableSize } from '../../../../../../selectors/screenSelectors';
import { connect } from 'react-redux';
import {numberFormat} from "../../../../../../services/helpers";
import createSelect from '../../../../../common/table/createSelect';
import NumberInput from '../../../../../common/table/NumberInput';
import StringInput from '../../../../../common/table/StringInput';

class Table extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    render(){
        const { defaultPageSize } = this.props;
        return (
            <div className={`table-main-wrap mt-50 ${!this.props.propsArray.length && this.props.propsGetDataStatus == 'pending' ? 'table-main-wrap_loading' : '' }`}>
                <div className="table-header">Інформація про ціну</div>
                <ReactTable
                    getTheadFilterThProps={(rowInfo) => {
                        return {
                            style: {
                                overflow: 'visible',
                                textAlign: 'left'
                            }
                        }
                    }}
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: () => {
                                    this.props.propsRowClick({
                                        id: rowInfo.row._original.ID,
                                        index: rowInfo.index
                                    });
                                },
                                style: {
                                    backgroundColor: rowInfo.index === this.props.index ? '#B8B8B8' : 'white',
                                    color: rowInfo.index === this.props.index ? 'white' : 'black',
                                    cursor: 'pointer'
                                }
                            }
                        }else{
                            return {}
                        }
                    }}
                    data={this.props.propsArray}
                    columns={[
                        {
                            Filter: StringInput,
                            Header: "Назва компанії",
                            accessor: "Company.Name",
                            id: 'Company/Name'
                        },
                        {
                            Header: "Тип палива",
                            accessor: "TypeFuel.FuelName",
                            id: 'TypeFuelID',
                            minWidth: 150,
                            Filter: createSelect({ options: this.props.data.fuelList, placeholder: 'Виберіть' })
                        },
                        {
                            Filter: NumberInput,
                            Header: "Ціна продажу, грн./л.",
                            className: "rightAlign",
                            id: "Price",
                            accessor: item => {
                                return item.Price ? numberFormat(+item.Price) : '-----------'
                            }
                        },
                    ]}
                    defaultPageSize={defaultPageSize}
                    className=" -highlight"
                    pageSize={this.props.pageSize}
                    page={this.props.page}
                    onPageSizeChange={this.props.handleChangePageSize}
                    onPageChange={this.props.handleChangePage}
                    manual={true}
                    filterable
                    sortable
                    multiSort
                    onFilteredChange={this.props.handleOnFilterChange}
                    onSortedChange={this.props.handleOnSortChange}
                    filtered={this.props.filtered}
                    sorted={this.props.sorted}
                    pages={Math.ceil(this.props.count / this.props.pageSize)}
                    NoDataComponent={() => null}
                    previousText="Назад"
                    nextText="Вперед"
                    rowsText="рядків"
                    pageText="Сторінка"
                    ofText="з"
                />

                <div className="loading"></div>
            </div>
        )

    }
}

const mapStateToProps = state => ({
    defaultPageSize: selectDefTableSize(state),
});

export default connect(mapStateToProps)(Table);
