import React, { Component } from 'react';
import { connect } from 'react-redux'

import Sidebar from '../../Sidebar';
import Wrapper from '../../../index';
import Table from './items/Table';

import api from '../../../../../api/index';
import { showModal } from "../../../../../actions/modals";
import {toPascalCase} from "../../../../../services/helpers";
import getCount from "../../../../../helpers/getCount";
import sortConverter from "../../../../../helpers/sortConverter";
import { stringConverter, selectNumberConverter, numberConverter, createFilter, dateConverter } from "../../../../../helpers/filterConverters";
import { toFuelList } from '../../../../../helpers/toLists';
import { setFilterData } from '../../../../../actions/filtersReducers';

const convertersMap = {
    'Employee/Department/Company/Name': stringConverter,
    'Employee/Department/Name': stringConverter,
    'Employee/Surname': stringConverter,
    'Employee/Name': stringConverter,
    'Employee/Patronymic': stringConverter,
    'Employee/PhoneNumber': stringConverter,
    'AuditFuel/Barrel/TypeFuelID': selectNumberConverter,
    'AuditFuel/DocNumber': stringConverter,
    'AuditFuel/FuelDate': dateConverter,
    'AuditFuel/Amount': numberConverter,
    'AuditFuel/Price': numberConverter,
    'AuditFuel/Capacity': numberConverter
}


class Index extends Component {

    constructor(props){
        super(props);
        this.state = {
            filtered: [{
                id: 'AuditFuel/FuelDate',
                value: {
                    from: new Date(new Date().setHours(0,0,0,0)),
                    to: new Date(new Date().setHours(23,59,59,0)),
                },
            }],
            sorted: [{ id: 'AuditFuel/FuelDate', desc: true }],
            count: 0,
            pageSize: 10,
            page: 0,
            rowID: null,
            index: null,
            getDataStatus: 'success',
            arraySort: [],
            data: {},
        };
    }

    handleChangePageSize = (pageSize) => {
        this.setState({ page: 0, pageSize }, () => this.handleSort())
    }

    handleChangePage = (page) => {
        this.setState({ page }, () => this.handleSort())
    }

    handleSort = async () => {
        const data = {
            filter: createFilter(this.state.filtered, convertersMap),
            order: sortConverter(this.state.sorted),
            top: this.state.pageSize,
            skip: this.state.pageSize * this.state.page
        };

        this.setState({
            getDataStatus: 'pending',
        });

        const res = await api.apiGetRegisterAuditData(data);
        this.setState({
            getDataStatus: 'success',
            index: null,
            rowID: null,
            count: getCount(res),
            arraySort: toPascalCase(res.data.value)
         });
    };


    static filterType = 'REGISTER_AUDIT'

    componentWillUnmount() {
        this.props.dispatch(setFilterData(this.state.filtered, Index.filterType));
    }

    async componentDidMount(){
        const fuelList = await api.apiGetFuelList().then(toFuelList);
        this.setState({
            filtered: this.props.filtersReducer[Index.filterType] || this.state.filtered,
            data: {
                fuelList
            }
        }, this.handleSort)
    }

    handleSetState = (data, callback) => this.setState(data, callback);

    handleRowClick = (data) => {
        this.setState({
            rowID: data.id,
            index: data.index
        });
    };

    handleDelete = () => {
        let tmpObj = this.findObject();
        tmpObj.callback = this.handleSort;
        this.props.dispatchShowModal('REGISTER_AUDIT_DELETE_MODAL', tmpObj);
    };

    handleEdit = () => {
        let tmpObj = this.findObject();
        tmpObj.callback = this.handleSort;
        this.props.dispatchShowModal('REGISTER_AUDIT_EDIT_MODAL', tmpObj);
    };

    handleCreate = () => {
        let tmpObj = {};
        tmpObj.callback = this.handleSort;
        this.props.dispatchShowModal('REGISTER_AUDIT_CREATE_MODAL', tmpObj)
    };

    findObject = () => {
        let tmpObj = {};
        for(let i = 0; i < this.state.array.length; i++){
            if(this.state.rowID == this.state.array[i].ID){
                tmpObj = Object.assign({}, this.state.array[i]);
            }
        }
        return tmpObj;
    };

    handleOnFilterChange = (filtered = []) => {
        this.setState({ filtered }, this.handleSort)
    }

    handleOnSortChange = (sorted = []) => {
        this.setState(
            { sorted }, this.handleSort);
    }

    handleClearFilter = async () => {
        this.setState({
            page: 0,
            filtered: []
        }, this.handleSort);
    };

    render(){
        return (
            <Wrapper>
                <div className="section">
                    <div className="row">
                        <div className="col-lg-12">
                            <Table
                                handleChangePageSize={this.handleChangePageSize}
                                handleChangePage={this.handleChangePage}
                                count={this.state.count}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                propsRowID={this.state.rowID}
                                propsRowClick={this.handleRowClick}
                                propsArraySort={this.state.arraySort}
                                propsGetDataStatus={this.state.getDataStatus}
                                index={this.state.index}
                                handleOnFilterChange={this.handleOnFilterChange}
                                handleOnSortChange={this.handleOnSortChange}
                                filtered={this.state.filtered}
                                sorted={this.state.sorted}
                                data={this.state.data}
                            />
                        </div>
                    </div>
                    <Sidebar
                        onClear={this.handleClearFilter}
                        propsSelected={{registerAudit: true}}
                        showNavigation={true}
                    />
                </div>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filtersReducer: state.filtersReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        dispatchShowModal: (type, data) => dispatch(showModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
