import React from 'react';
import Select from '../Select';
import ClearButton from '../ClearButton';

const createSelect = ({ options, Option, placeholder }) => ({ onChange, filter }) => (
    <>
        <ClearButton className="right-margin table-clear" onClick={() => onChange('')} />
        <Select
            components={!Option ? undefined : { Option }}
            placeholder={placeholder}
            value={!filter ? '' : filter.value}
            onChange={onChange}
            options={options}
        />
    </>
);

export default createSelect;