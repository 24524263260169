import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import './style.css';

import AppMain from "./components/index";
import store from './store/index';
import isMobile from "./helpers/isMobile";
import { signIn } from "./helpers/msalAuth2";

isMobile(store.dispatch);

const App = () => (
    <BrowserRouter>
        <Provider store={store}>
            <Route component={AppMain} />
        </Provider>
    </BrowserRouter>
)

render(<App />, document.getElementById('root'));
