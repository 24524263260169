import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

import { renderError, normalizeNumberMaxDecimals } from "../../../../../../services/helpers";
import validator from '../../../../../../services/DataValidator';
import api from '../../../../../../api/index';
import { showModal, hideModal } from "../../../../../../actions/modals";
import Spiner from "../../../../../common/Spiner";

const typeRegister = [
    { value: '1', label: 'Кошти' },
    { value: '2', label: 'Літри' },
];

class CompanyModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            ID: '',
            address: '',
            closed: false,
            name: '',
            limit: 10,
            payType: '',
            typePaymentList: [
                { value: 1, label: 'Відомість Д' },
                { value: 0, label: 'Готівковий розрахунок' },
            ],
            selectedTypeRegister: {
                value: '1', label: 'Кошти'
            },
            selectedTypeTerm: null,

            callback: () => {},

            validation: []
        };
    }

    componentDidMount(){
        if(typeof this.props.propsData != 'undefined'){
            this.setState({
                ID: this.props.propsData.ID,
                address: this.props.propsData.Address,
                closed: this.props.propsData.Closed,
                name: this.props.propsData.Name,
                limit: this.props.propsData.Limit === null ? '' : this.props.propsData.Limit,
                payType: !this.props.propsData.TypePayment ? '' : {
                    value: this.props.propsData.TypePayment.ID,
                    label: this.props.propsData.TypePayment.PaymentName,
                },
                selectedTypeRegister: {
                    value: '1', label: 'Кошти'
                },
                selectedTypeTerm: !this.props.propsData.TypeTerm ? null : {
                    value: this.props.propsData.TypeTerm.ID,
                    label: this.props.propsData.TypeTerm.TermName,
                },
                callback: this.props.propsData.callback,
            })
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(event.target.dataset.type == 'number'){
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        this.setState({
            [name]: value
        });
    };

    handleTypeTerm = (selectedOption) => {
        this.setState({
            selectedTypeTerm: selectedOption
        });
    };

    handleSave = () => {

        let validationResult = validator.arrayValidation([
            { id: 'name', name: 'Назва', type: 'EMPTY', value: this.state.name },
            { id: 'address', name: 'Адреса', type: 'EMPTY', value: this.state.address },
            { id: 'payType', name: 'Форма оплати', type: 'EMPTY', value: this.state.payType }
        ]);

        if(validationResult.state == 'success'){
            this.setState({ loading: true });
            let ID = this.state.ID;

            let dataToSend = {
                ID: this.state.ID,
                Address: this.state.address,
                Closed: this.state.closed,
                Name: this.state.name,
                Limit: !(+this.state.limit) ? null : +this.state.limit,
                TypeRegisterID: +this.state.selectedTypeRegister.value,
                TypeTermID: this.state.selectedTypeTerm == null ? null : +this.state.selectedTypeTerm.value,
                TypePaymentID: +this.state.payType.value,
            };

            api.apiEditCompany(dataToSend, ID).then(res => {
                this.setState({ loading: false });
                if(res.status == 204){
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагувати компанію', description: 'Компанія була успішно відредагована', type: true });
                    this.state.callback();
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагувати компанію', description: 'Компанія не була відредагована. Зверніться до адміністратора', type: false });
                }
            }).catch(error => {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Редагувати компанію', description: error.response.data.message, type: false });
            });
        }

        this.setState({
            validation: validationResult.errorArr
        });
    };



    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Редагувати компанію</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Назва</h5>
                                <div className="form-group">
                                    <input
                                        disabled
                                        placeholder="Введіть назву"
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={this.state.name}
                                    />
                                    <div className="form-group-error">
                                        {renderError('name', this.state.validation)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Адреса</h5>
                                <div className="form-group">
                                    <input
                                        disabled
                                        placeholder="Введіть адресу"
                                        type="text"
                                        className="form-control"
                                        name="address"
                                        value={this.state.address}
                                    />
                                    <div className="form-group-error">
                                        {renderError('address', this.state.validation)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Тип обліку</h5>
                                <div className="checkbox">
                                    <Select
                                        isDisabled
                                        value={this.state.selectedTypeRegister}
                                        options={typeRegister}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Кількість ліміту</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть кількість ліміту"
                                        type="text"
                                        className="form-control"
                                        name="limit"
                                        data-type="number"

                                        value={this.state.limit}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        {renderError('limit', this.state.validation)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Термін ліміту</h5>
                                <div className="checkbox">
                                    <Select
                                        placeholder="Виберіть термін ліміту"
                                        value={this.state.selectedTypeTerm}
                                        onChange={this.handleTypeTerm}
                                        options={this.props.typeTerm}
                                        isClearable
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Форма оплати</h5>
                                <Select
                                    isDisabled
                                    placeholder="Виберіть форму оплати"
                                    value={this.state.payType}
                                    options={this.state.typePaymentList}
                                />
                            </div>
                            <div className="form-group-error">
                                {renderError('payType', this.state.validation)}
                            </div>
                        </div>
                        <div className="col-lg-12">
                        <div className="modal__row">
                            <h5>Заблоковано</h5>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="closed"

                                        checked={this.state.closed}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        {renderError('closed', this.state.validation)}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>

                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Зберегти</span>
                                        : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        typeTerm: typeof state.user.data.typeTermList != "undefined" ? state.user.data.typeTermList: []
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyModal);
