import React, { Component } from 'react';
import { connect } from 'react-redux'

import Sidebar from '../cod/Sidebar';
import Wrapper from '../index';

import reports from '../../../assets/json/reports'

import { showModal } from "../../../actions/modals";
import Filter from "./items/Filter";


class Index extends Component {

    constructor(props){
        super(props);
        this.state = {
            reportGroups: [],
            reportGroupsToView: [],
            filter: false
        };
    }

    componentWillMount() {

        let reportGroups = reports.reportGroups.map(item => {
            return {
                ...item,
                reports: item.reports.sort((a,b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0))
            }
        });

        reportGroups = reportGroups.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));

        this.setState({
            reportGroups,
            reportGroupsToView: reportGroups
        })
    }

    handleCreate = (item) => this.props.dispatchShowModal(`${item.name.toUpperCase()}_REPORT_MODAL`, item);

    onFilter = () => {
        this.setState({filter: !this.state.filter})
    };

    handleSetState = (field, value) => this.setState({ [field]: value });

    renderItem = (item, index) => {

        return (
            <div key={index} className="reports__item">
                <div className="reports__text">
                    { item.description }
                </div>
                <div className="reports__btn">
                    <button onClick={() => this.handleCreate(item)} type="button" className="btn btn-dark">Сформувати</button>
                </div>
            </div>
        )
    };

    handleToggleShowGroup = (item) => {
        this.setState({
            [item.key + 'visible']: !this.state[item.key + 'visible']
        })
    };

    renderReports = () => {

        const { reportGroupsToView } = this.state

        return (
            <div>
                {
                    reportGroupsToView.map((item, index) => {
                        return (
                            <div key={index} className={`${typeof this.state[item.key + 'visible'] != 'undefined' && this.state[item.key + 'visible'] ? 'active': ''} filter`}>
                                <div className="df filter-top" onClick={() => this.handleToggleShowGroup(item)}>
                                    <h3>{ item.title }</h3>
                                    {
                                        typeof this.state[item.key + 'visible'] != 'undefined' && this.state[item.key + 'visible'] ? <i className="fas fa-caret-up"></i> : <i className="fas fa-caret-down"></i>
                                    }
                                </div>
                                {
                                    item.reports.length ? item.reports.map((item, index) => {
                                        return this.renderItem(item, index)
                                    }) : <div>(Пусто)</div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    };

    render(){

        const { reportGroups } = this.state

        return (
            <Wrapper>
                <div className="section">
                    <Filter
                        reportGroups={reportGroups}
                        handleSetState={this.handleSetState}
                        filter={this.state.filter}
                        onFilter={this.onFilter}
                    />
                    <div>
                        { this.renderReports() }
                    </div>
                    <Sidebar
                        onFilter={() => this.onFilter()}
                        showNavigation={false}
                    />
                </div>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);