import React from 'react';

const ClearButton = ({ onClick, className }) => (
    <div className={`clear ${className || ''}`}>
        <button type="button" onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19"
                 stroke="black" strokeWidth="2"
            >
                <line x1="4" x2="15" y1="4" y2="15"></line>
                <line x1="15" x2="4" y1="4" y2="15"></line>
            </svg>
        </button>
    </div>
);

export default ClearButton;