export function renderError(field, array){
    if(array.length > 0){
        let result = array.find((array) => { return array.field === field; });
        if(typeof result != 'undefined'){
            return result.msg;
        }
    }
    else return '';
}

export function normalizeNumberMaxDecimals(val, max, decimals) {
    let tmpSplit;
    let value = val.replace(/[^0-9.]*/g, '');
    value = value.replace(/\.{2,}/g, '.');
    value = value.replace(/\.,/g, ',');
    value = value.replace(/\,\./g, ',');
    value = value.replace(/\,{2,}/g, ',');
    value = value.replace(/\.[0-9]+\./g, '.');

    tmpSplit = value.split('.');

    if(typeof tmpSplit[1] != 'undefined' && tmpSplit[1].length > 1){
        value = tmpSplit[0] + "." + tmpSplit[1].substring(0, decimals);
    }

    value = (value[0] == '0' && value[1] == '0') || (value[0] == '0' && +value[1]) ? '0' : value;
    value = value > max ? max : value;
    return !value || value == '.' ? '' : value;
}

export const numberFormat = (data) => {
    data = data.toFixed(2);

    data = data.toString();

    let tmp = data.split('.');

    let first = tmp[0].replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ');

    let second = typeof tmp[1] == 'undefined' ? '00' : tmp[1];

    return first + '.' + second;
};

export const validateText = (str) => {let ars = str.replace(/[^a-zA-ZА-Яа-яёЁЇїІіЄєҐґ]/gi,'').replace(/\s+/gi,', '); return ars;};

export const toPascalCase = (o) => {
    let newO, origKey, newKey, value;
    if (o instanceof Array) {
        return o.map((value) => {
            if (typeof value === "object") {
                value = toPascalCase(value)
            }
            return value
        })
    } else {
        newO = {};
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                if(origKey == 'id'){
                    newO['ID'] = o[origKey]
                    continue
                }
                newKey = (origKey.charAt(0).toUpperCase() + origKey.slice(1) || origKey).toString();
                value = o[origKey]
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    value = toPascalCase(value)
                }
                newO[newKey] = value
            }
        }
    }
    return newO
};
