import { applyMiddleware, compose, createStore } from "redux";
import indexReducer from "../reducers";
import thunk from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(indexReducer,
    composeEnhancers(
        applyMiddleware(thunk)
    )
);

export default store;