
export function login(data) {
    return {
        type: 'USER_LOGIN',
        user: data.userCredentials,
        auth: true,
        initData: true
    }
}

export function toggleSidebar() {
    return {
        type: 'TOGGLE_SIDEBAR'
    }
}

export function closeSidebar() {
    return {
        type: 'CLOSE_SIDEBAR'
    }
}

export function initData(data) {
    return {
        type: 'GET_INIT_DATA',
        data: data
    }
}
