
const user  = (state = { userCredentials: { token: '', authContext: {} }, auth: null, data: {}, initData: false, sidebar: false }, action) => {
    switch(action.type){
        case 'USER_LOGIN':
            return new Object({
                userCredentials: action.user,
                auth: action.auth,
                data: state.data,
                initData: false,
                sidebar: state.sidebar,
            });
        case 'USER_LOGOUT':
            return new Object({
                userCredentials: { token: '', authContext: {} },
                auth: action.auth,
                data: {},
                initData: false,
                sidebar: state.sidebar,
            });
        case 'GET_INIT_DATA':
            return new Object({
                userCredentials: state.userCredentials,
                auth: state.auth,
                data: action.data,
                initData: true,
                sidebar: state.sidebar,
            });
        case 'TOGGLE_SIDEBAR':
            return new Object({
                ...state,
                sidebar: !state.sidebar
            });
        case 'CLOSE_SIDEBAR':
            return new Object({
                ...state,
                sidebar: false
            });
    }
    return state;
};

export default user;
