import { CHANGE_IS_MOBILE } from '../actionTypes';
const getScreenWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export default (dispatch) => {
  dispatch({
    type: CHANGE_IS_MOBILE,
    payload: getScreenWidth(),
  });

  window.addEventListener('resize', () => {
    dispatch({
      type: CHANGE_IS_MOBILE,
      payload: getScreenWidth(),
    });
  })
}
