import React from 'react';
import { Route } from 'react-router-dom';

import Auth from '../../etc/Auth';

const DashboardRoute = ({ component: Component, ...rest }) => (
    <Auth>
        <Route
          {...rest}
          children={<Component />}
        />
    </Auth>
);

export default DashboardRoute;
