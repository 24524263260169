import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toggleSidebar, closeSidebar } from "../../../actions";
import { selectIsMobile } from "../../../selectors/screenSelectors";

class Sidebar extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    closeMenu = () => {
        if (this.props.isMobile) this.props.closeSidebar();
    };

    checkMenu = (e) => {
        if(
          !this.menuBlock ||
          !this.props.sidebar ||
          e.target === this.menuBlock ||
          this.menuBlock.contains(e.target)

        ) return;
        this.closeMenu();
    };

    onClear = () => {
        if (this.props.onClear) this.props.onClear();
        this.closeMenu();
    }

    propsOnCreate = () => {
        if (this.props.propsOnCreate) this.props.propsOnCreate();
        this.closeMenu();
    }

    propsOnEdit = () => {
        if (this.props.propsOnEdit) this.props.propsOnEdit();
        this.closeMenu();
    }

    propsOnDelete = () => {
        if (this.props.propsOnDelete) this.props.propsOnDelete();
        this.closeMenu();
    }

    createPush = (path) => () => {
        this.props.history.push(path);
        this.closeMenu();
    }

    componentDidMount(){
        document.addEventListener('click', this.checkMenu)
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.checkMenu)
    }

    render(){
        const { isMobile, showNavigation } = this.props;
        return (
          <section ref={ref => this.menuBlock = ref}>
              {
                  !isMobile || !showNavigation ? null : (
                    <div
                      onClick={() => this.props.toggleSidebar()}
                      className={`tool-bar__item tool-bar__item_hide tool-bar__button-mobile`}
                    >
                        {
                            this.props.sidebar ? <i className="fas fa-angle-double-left" /> : <i className="fas fa-angle-double-right" />
                        }
                    </div>
                  )
              }
              {
                  !isMobile ? null : (
                    <div className="tool-bar__mobile-crud">
                        {
                            this.props.onClear ?
                            <div onClick={this.onClear} className="tool-bar__item">
                                <i className="fas fa-ban" />
                            </div> : null
                        }
                        {
                            this.props.propsOnCreate ?
                            <div onClick={this.propsOnCreate} className="tool-bar__item">
                                <i className="fas fa-plus" />
                            </div> : null
                        }
                        {
                            this.props.propsOnEdit ?
                            <div onClick={this.propsOnEdit} className={`${this.props.propsDisableEdit ? 'disabled' : ''} tool-bar__item`}>
                                <i className="fas fa-edit" />
                            </div> : null
                        }
                        {
                            this.props.propsOnDelete ?
                            <div onClick={this.propsOnDelete} className={`${this.props.propsDisableDelete ? 'disabled' : ''} tool-bar__item`}>
                                <i className="fas fa-trash" />
                            </div> : null
                        }
                    </div>
                  )
              }
                <div className={`${this.props.sidebar ? 'active' : ''} tool-bar`}>
                    <ul className="tool-bar__list">
                        {
                            isMobile ? null : (
                              <li onClick={() => this.props.toggleSidebar()} className={`tool-bar__item tool-bar__item_hide`}>
                                  {
                                      this.props.sidebar ? <i className="fas fa-angle-double-left" /> : <i className="fas fa-angle-double-right" />
                                  }
                              </li>
                            )
                        }
                        {
                            this.props.onClear ?
                            <div onClick={this.onClear} className="tool-bar__item">
                                <i className="fas fa-ban" /><span>Очистити фільтр</span>
                            </div> : null
                        }
                        {
                            this.props.propsOnCreate && !isMobile ?
                                <li onClick={this.propsOnCreate} className="tool-bar__item">
                                    <i className="fas fa-plus" /><span>Створити</span>
                                </li> : null
                        }
                        {
                            this.props.propsOnEdit && !isMobile ?
                                <li onClick={this.propsOnEdit} className={`${this.props.propsDisableEdit ? 'disabled' : ''} tool-bar__item`}>
                                    <i className="fas fa-edit" /><span>Редагувати</span>
                                </li> : null
                        }
                        {
                            this.props.propsOnDelete && !isMobile ?
                                <li onClick={this.propsOnDelete} className={`${this.props.propsDisableDelete ? 'disabled' : ''} tool-bar__item`}>
                                    <i className="fas fa-trash" /><span>Видалити</span>
                                </li> : null
                        }
                        <li className="tool-bar__line" />
                    </ul>
                </div>
              </section>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sidebar: state.user.sidebar,
        isMobile: selectIsMobile(state),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSidebar: () => {
            dispatch(toggleSidebar())
        },
        closeSidebar: () => dispatch(closeSidebar()),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
