export const selectNumberConverter = ({ id, value: { value } = {}}) => {
    if (value === '' && typeof value === 'undefined') return [];
    return  [`${id} eq ${value}`];
}

export const selectStringConverter = ({ id, value: { value } = {}}) => {
    if (value === '' && typeof value === 'undefined') return [];
    return  [`${id} eq '${value}'`];
}

export const stringConverter = ({ id, value }) => {
    if (value === '') return [];
    return  [`(${id.split('|').map((id) => `contains(${id}, '${value}')`).join(' or ')})`];
}

export const singleNumberConverter = ({ id, value}) => {
    if (value === '') return [];
    return  [`${id} eq ${Number(value)}`]
}

export const booleanConverter = ({ id, value : { value } = {} }) => {
    if (value === '') return [];
    return  [`${id} eq ${value}`]
}

export const nullOrNotConverter = ({ id, value: { value } = {} }) => {
    if (value === '' && typeof value === 'undefined') return [];
    return [`${id} ${value === '1' ? 'ne' : 'eq'} null`];
};

export const typeAccountingConverter = ({ id, value: { value } = {} }) => {
    if (value === '' && typeof value === 'undefined') return [];
    return [`TypeFuelID ${value === '1' ? 'ne' : 'eq'} null`];
};

export const dateConverter = ({ id, value: { from, to } = {} }) => {
    const res = []
    if (from) res.push(`${id} ge ${new Date(from).toISOString()}`);
    if (to) res.push(`${id} le ${new Date(to).toISOString()}`)
    return res
};

export const numberConverter = ({ id, value: { min, max } = {} }) => {
    const res = []
    if (min) res.push(`${id} ge ${min}`);
    if (max) res.push(`${id} le ${max}`)
    return res
};

export const createFilter = (filtered, convertersMap) => {
    return filtered.reduce((res, filter) => [...res, ...convertersMap[filter.id](filter)], [])
}