import React from "react";
import ReactSelect from 'react-select';

const Select = (props) => (
    <ReactSelect
        styles={{
            menu: provided => ({ ...provided, zIndex: 9999 })
        }}
        {...props}
    />
);

export default Select