import React, { Component } from 'react';
import { connect } from 'react-redux'

import Sidebar from '../../Sidebar';
import Wrapper from '../../../index';
import Table from './items/Table';

import api from '../../../../../api/index';
import { showModal } from "../../../../../actions/modals";
import {toPascalCase} from "../../../../../services/helpers";
import getCount from "../../../../../helpers/getCount";
import sortConverter from "../../../../../helpers/sortConverter";
import { stringConverter, booleanConverter, selectNumberConverter, numberConverter, createFilter } from "../../../../../helpers/filterConverters";
import { setFilterData } from '../../../../../actions/filtersReducers';

const convertersMap = {
    'Department/Company/Name': stringConverter,
    'Department/Name': stringConverter,
    Surname: stringConverter,
    Name: stringConverter,
    Patronymic: stringConverter,
    PhoneNumber: stringConverter,
    'Card/Name': stringConverter,
    Limit: numberConverter,
    TypeTermID: selectNumberConverter,
    Closed: booleanConverter,
}

class Index extends Component {

    constructor(props){
        super(props);
        this.state = {
            filtered: [],
            sorted: [],
            count: 0,
            pageSize: 10,
            page: 0,
            rowID: null,
            index: null,
            getDataStatus: 'success',
            employeeArray: []
        };
    }

    static filterType = 'EMPLOYEE'

    componentWillUnmount() {
        this.props.dispatch(setFilterData(this.state.filtered, Index.filterType));
    }

    async componentDidMount(){
        this.setState({
            filtered: this.props.filtersReducer[Index.filterType] || this.state.filtered,
        }, this.handleGetData)
    }

    handleChangePageSize = (pageSize) => {
        this.setState({ page: 0, pageSize }, this.handleGetData)
    }

    handleChangePage = (page) => {
        this.setState({ page }, this.handleGetData)
    }

    handleGetData = async () => {

        this.setState({
            getDataStatus: 'pending'
        });

        const data = {
            filter: createFilter(this.state.filtered, convertersMap),
            order: sortConverter(this.state.sorted),
            top: this.state.pageSize,
            skip: this.state.pageSize * this.state.page
        };

        let res = await api.apiGetEmployeeData(data);

        this.setState({
            rowID: null,
            employeeArray: toPascalCase(res.data.value),
            getDataStatus: 'success',
            count: getCount(res)
        });

    };

    handleRowClick = (data) => {
        this.setState({
            rowID: data.id,
            index: data.index
        });
    };

    handleEdit = () => {
        let tmpObj = this.findObject();
        tmpObj.callback = this.handleGetData;
        this.props.dispatchShowModal('EMPLOYEE_EDIT_MODAL', tmpObj);
    };

    findObject = () => {
        let tmpObj = {};
        for(let i = 0; i < this.state.employeeArray.length; i++){
            if(this.state.rowID == this.state.employeeArray[i].ID){
                tmpObj = Object.assign({}, this.state.employeeArray[i]);
            }
        }
        return tmpObj;
    };

    handleOnFilterChange = (filtered = []) => {
        this.setState({ filtered }, this.handleGetData)
    }

    handleOnSortChange = (sorted = []) => {
        this.setState(
            { sorted }, this.handleGetData);
    }

    handleClearFilter = async () => {
        this.setState({
            page: 0,
            filtered: []
        }, this.handleGetData);
    };

    render(){
        return (
            <Wrapper>
                <div className="section">
                    <div className="row">
                        <div className="col-lg-12">
                            <Table
                                handleChangePageSize={this.handleChangePageSize}
                                handleChangePage={this.handleChangePage}
                                count={this.state.count}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                propsRowID={this.state.rowID}
                                propsRowClick={this.handleRowClick}
                                propsArray={this.state.employeeArray}
                                propsGetDataStatus={this.state.getDataStatus}
                                index={this.state.index}
                                handleOnFilterChange={this.handleOnFilterChange}
                                handleOnSortChange={this.handleOnSortChange}
                                filtered={this.state.filtered}
                                sorted={this.state.sorted}
                            />
                        </div>
                    </div>
                    <Sidebar
                        propsOnEdit={() => this.handleEdit()}
                        propsDisableEdit={ this.state.rowID == null ? true : false }
                        propsSelected={{employee: true}}
                        showNavigation={true}
                        onClear={this.handleClearFilter}
                    />
                </div>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filtersReducer: state.filtersReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        dispatchShowModal: (type, data) => dispatch(showModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
