import React, { Component } from 'react';
import { connect } from 'react-redux';

class Wrapper extends Component{
    constructor(props) {
        super(props);
        this.state = {
            sidebarHide: false
        };
    }

    renderHtml = () => {
        return this.mainHtml()
    };

    mainHtml = () => {
        return(
            <div className={`${this.props.sidebar ? 'active' : ''} content-wrapper`}>
                { this.props.children }
            </div>
        )
    };

    render(){
        return(
            this.renderHtml()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sidebar: state.user.sidebar
    }
};

export default connect(mapStateToProps, {})(Wrapper);