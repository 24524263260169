import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { closeSidebar } from "../../actions";
import { showModal } from "../../actions/modals";

class Header extends Component {
    constructor(props){
        super(props);
        this.state = {
            opened: false,
        };
    }

    setOpened = (opened) => this.setState({ opened });

    closeMenu = () => this.setOpened(false);

    closeMenus = () => {
        this.closeMenu();
        this.props.closeSidebar();
    }

    toggleNav = () => {
        this.setState((state) => ({ opened: !state.opened }))
    }

    logout = () => {
        this.props.authContext.logout();
        this.closeMenu();
    }

    checkMenu = (e) => {
        if(
          !this.menuBlock ||
          !this.state.opened ||
          e.target === this.menuBlock ||
          this.menuBlock.contains(e.target)
        ) return;
        this.closeMenu();
    };

    componentDidMount(){
        document.addEventListener('click', this.checkMenu)
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.checkMenu)
    }

    render(){
        const { logout, toggleNav, closeMenu, closeMenus, props, state } = this;
        const { history: { location: { pathname }} } = props;
        const { opened } = state;
        return (
            <header className="header-c header-c_opened">
                <nav className="navbar navbar-fixed-top navbar-inverse">
                    <div className="container-fluid">
                        <div className="navbar-header" ref={ref => this.menuBlock = ref}>
                            <button
                              type="button"
                              className={`navbar-toggle ${opened ? '' : 'collapsed'}`}
                              aria-expanded="false"
                              aria-controls="navbar"
                              onClick={toggleNav}
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                            </button>
                            <a className="navbar-brand">PetrolBox.Client</a>
                        </div>
                        <div id="navbar" className={`${opened ? '' : 'collapse'} navbar-collapse`}>
                            <ul className="nav navbar-nav">
                                <li className={`${pathname === '/' ? 'active': ''}`}>
                                    <Link onClick={closeMenus} to="/">Картка</Link>
                                </li>
                                <li className={`${pathname === '/cod/company' ? 'active': ''} `}>
                                    <Link onClick={closeMenu} to="/cod/company">Компанія</Link>
                                </li>
                                <li className={`${pathname.includes('/cod/employee') ? 'active': ''} `}>
                                    <Link onClick={closeMenu} to="/cod/employee">Працівник</Link>
                                </li>
                                <li className={`${pathname === '/cod/register' ? 'active': ''} `}>
                                    <Link onClick={closeMenu} to="/cod/register">Реєстр</Link>
                                </li>
                                <li className={`${pathname.includes('/cod/price') ? 'active': ''} `}>
                                    <Link onClick={closeMenu} to="/cod/price">Ціна для компанії</Link>
                                </li>
                                <li className={`${pathname.includes('/cod/company-movement') ? 'active': ''} `}>
                                    <Link onClick={closeMenu} to="/cod/company-movement">Рух по компанії</Link>
                                </li>
                                <li className={`${pathname.includes('/cod/register-audit') ? 'active': ''} `}>
                                    <Link onClick={closeMenu} to="/cod/register-audit">Аудит реєстра</Link>
                                </li>
                                <li className={`${pathname.includes('/reports') ? 'active': ''}`}>
                                    <Link onClick={closeMenu} to="/reports">Звіти</Link>
                                </li>
                            </ul>
                            <ul className="nav navbar-nav navbar-nav navbar-right">
                                <li><div className="navbar-btn" onClick={logout} to="/">Вихід</div></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        authContext: state.user.userCredentials.authContext
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type) => dispatch(showModal(type)),
        closeSidebar: () => dispatch(closeSidebar()),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));


// BF79BF172de5
// bmohyla@polidecisions.com
