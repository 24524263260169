/***************************/
//Use __validator.arrayValidation to perform validation od array of data
//Single record should be object with next fields:
//id - identity of data/error field (for exp. 'password')
//type - type of validation. Should be in uppercase (for exp. 'PASSWORD')
//value - value
//name - (optionally) title that will be used in error message and not equal id (for exp. 'confirm password'). If not set -> will be used id.
/***************************/
function userDataValidation(obj){
    let id = obj.id;
    let type = obj.type;
    let value = obj.value;
    let optional = typeof obj.optional == 'undefined' ? false : true;
    let name = ( typeof obj.name == 'undefined' ) ? obj.id : obj.name;

    let error = {};

    if ( typeof value == 'undefined' ) {
        error.msg = 'Please fill in the ' + name + ' field';
        error.field = id;
        return error;
    }

    if(!value && optional){
        return false;
    } else {
        switch(type){
            case 'EMAIL':
                let pattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
                if(!value)
                    error.msg = 'Please fill in the ' + name + ' field';
                else if(!pattern.test(value)){
                    error.msg = 'Incorrect format of email. Example: site13@gmail.com';
                }
                break;
            case 'PASSWORD':
                if(!value)
                    error.msg = 'Заповніть ' + '"' + name + '"' + ' поле';
                break;
            case 'PASSWORDS_CHECK':
                let tmpCheck = passwordsValidation(obj);
                if(tmpCheck){
                    error.msg = tmpCheck;
                }
                break;
            case 'TEXT':
                if(!value)
                    error.msg = 'Please fill in the ' + name + ' field';
                else if(!/^[a-z]+$/i.test(value)){
                    error.msg = 'The ' + name + ' field may contain only Latin letters';
                }
                break;
            case 'COUNTRY':
                if(!value)
                    error.msg = 'Please fill in the ' + name + ' field';
                break;
            case 'WALLET_ADDRESS':
                if(!value){
                    error.msg = 'Please fill in the ' + name + ' field';
                } else if(value.length != 42) {
                    error.msg = 'Inappropriate format';
                }
                break;
            case 'CAPTCHA':
                if(!value){
                    error.msg = 'Please fill in the ' + name ;
                }
                break;
            case 'EMPTY':
                if(!value){
                    error.msg = 'Будь ласка, заповніть поле "' + name + '"';
                }else {
                    if (value.length > 255)
                        error.msg = 'The ' + name + ' must be less 255 characters';
                }
                break;
            case 'UNDEFINED':
                if(typeof value == 'undefined'){
                    error.msg = 'Please fill in the ' + name + ' field';
                }
                break;
            case 'ARRAY_EMPTY':
                if(!value.length){
                    error.msg = 'Data ' + name + ' is empty';
                }
                break;
            default:
                break;
        }
    }

    if (typeof error.msg != 'undefined') {
        error.field = id;
        return error;
    }
    return false;
}
function passwordsValidation(obj){
    let only =  /^[a-zA-Z0-9.,\/#!$%\^&\*;:{}=\-_`~()]+$/;

    if(obj.value[0] && obj.value[1]){
        if(obj.value[0] != obj.value[1]){
            return 'Please enter the same passwords';
        }else if(obj.value[0].length <= 6){
            return 'Password must be more than six characters';
        } else if(!only.test(obj.value[0])){
            return 'Password should contain only latin letters, numbers and special characters';
        }
    }
    return '';
}


module.exports = {
    arrayValidation: function (array) {
        let resultArray = [];
        for (let i = 0; i < array.length; i++) {
            let validRes =  userDataValidation(array[i]);
            if (validRes) {
                resultArray.push(validRes);
            }
        }
        if (resultArray.length > 0) {
            return {
                state: 'fail',
                errorArr: resultArray
            };
        } else {
            return {
                state: 'success',
                errorArr: resultArray
            };
        }
    }
};


